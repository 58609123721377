import classNames from "classnames";
import * as React from "react";
import { useEffect, useState } from "react";
import Layout from "../layout/Layout";

/**
 * Chest: https://www.pacsbin.com/collection/-1Gk5BW9Gu/review
 * Extremities: https://www.pacsbin.com/collection/-y3LHFtogd/review
 * Abdomen: https://www.pacsbin.com/collection/Zkh2xLW9M_/review
 * Neuro: https://www.pacsbin.com/collection/623db20885ad46625f144dff/review?code=f8707e1b-5e67-4e1d-8a19-6f84d1ba5aab
 */

const baseAddress = "https://pacs-backup.nordictraumarad.com";

const links = {
  thorax: {
    name: "Chest",
    link: `${baseAddress}/?patientName=chest%2A&sortBy=patientName&sortDirection=descending`,
  },
  thoraxWorkbook: {
    name: "Chest trauma workbook",
    link: "/files/ChestBluntTrauma2024Workbook.pdf",
  },
  msk: {
    name: "MSK",
    link: `${baseAddress}/?patientName=MSK%2A&resultsPerPage=50&sortBy=patientName&sortDirection=descending`,
  },
  abdomen: {
    name: "Abdomen",
    link: `${baseAddress}/?patientName=abdcase%2A&sortBy=patientName&sortDirection=descending`,
  },
  adbomenWorkbook: {
    name: "Abdominal trauma workbook",
    link: "/files/Workshop_abdomen_trauma_radiology_cases_Stockholm_2024_Dormagen.pdf",
  },
  neuro: {
    name: "Neuro",
    link: `${baseAddress}/?patientName=neuro%2A&sortBy=patientName&sortDirection=descending`,
  },
};

const schedule = [
  {
    name: "Registration",
    comment: "Optional testing of DICOM viewer",
    time: "9:00 - 9:20",
  },
  {
    name: "Session 1",
    links: { A: [links.msk], B: [links.abdomen, links.adbomenWorkbook] },
    time: "9:20 - 10:30",
  },
  { name: "Break with coffee", time: "10:30 - 10:50" },
  {
    name: "Session 2",
    links: { A: [links.abdomen, links.adbomenWorkbook], B: [links.msk] },
    time: "10:50 - 12:00",
  },
  { name: "Lunch", time: "12:00 - 12:40" },
  {
    name: "Session 3",
    links: { A: [links.neuro], B: [links.thorax, links.thoraxWorkbook] },
    time: "12:40 - 13:50",
  },
  { name: "Break with coffee", time: "13:50 - 14:10" },
  {
    name: "Session 4",
    links: { A: [links.thorax, links.thoraxWorkbook], B: [links.neuro] },
    time: "14:10 - 15:20",
  },
  // { name: "Break", time: "15:20 - 15:30" },
  {
    name: "Final comments",
    // comment: "What did I learn?",
    time: "15:20 - 15:30",
  },
];

interface WorkshopPageProps {}
const WorkshopPage: React.FC<WorkshopPageProps> = ({}) => {
  const [loggedIn, setLoggedIn] = useState(false);
  const [formError, setFormError] = useState(false);
  const [pwd, setPwd] = useState("");
  const [group, setGroup] = useState<null | string>(null);

  const groupList = [
    { letter: "A", room: "Room D6:56" },
    { letter: "B", room: "Room D6:58" },
  ];

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (pwd == "TrWorksh0p2024") {
      setLoggedIn(true);
      setFormError(false);
      localStorage.setItem("wsLoggedIn", "true");
    } else {
      setFormError(true);
    }
  };

  useEffect(() => {
    if (localStorage.getItem("wsLoggedIn") == "true") {
      setLoggedIn(true);
    }
    const wsGroup = localStorage.getItem("wsGroup");
    if (wsGroup) {
      setGroup(wsGroup);
    }
  }, [setLoggedIn, setGroup]);

  if (loggedIn) {
    return (
      <Layout title="Workshop">
        <h2 className="text-center text-3xl">Workshop</h2>
        <div className="flex flex-col items-center mt-4">
          <h3>Select group:</h3>
          <div className="flex">
            {groupList.map(({ letter, room }) => (
              <button
                className={classNames([
                  "px-4 py-2",
                  group == letter ? "bg-red-800" : "bg-red-600",
                ])}
                onClick={() => {
                  setGroup(letter);
                  localStorage.setItem("wsGroup", letter);
                }}
              >
                {letter} - {room}
              </button>
            ))}
          </div>
        </div>
        {group && (
          <div className="mt-4 text-center pt-2 bg-red-800">
            <h3 className="text-2xl">
              Program group {group} -{" "}
              {groupList.find((grp) => grp.letter == group)?.room}{" "}
            </h3>
            {schedule.map((event) => (
              <div className="mt-1 bg-zinc-800 py-2 px-4 flex flex-col items-center">
                <p>{event.time}</p>
                <h4 className="text-lg">{event.name}</h4>
                {event.comment && (
                  <p className="text-zinc-400">{event.comment}</p>
                )}
                {event.links &&
                  (group == "A" || group == "B") &&
                  event.links[group].map((linkItem) => (
                    <a
                      className="mt-2 bg-red-800 py-1 px-2 rounded mr-2"
                      href={linkItem.link}
                      target="_blank"
                    >
                      {linkItem.name}
                    </a>
                  ))}
              </div>
            ))}
          </div>
        )}
      </Layout>
    );
  } else {
    return (
      <Layout>
        <h2 className="text-3xl text-center">Workshop</h2>
        <form
          className="mt-3 px-10 py-5 bg-zinc-800 rounded flex flex-col items-center mx-auto max-w-2xl border border-red-700"
          onSubmit={handleSubmit}
        >
          <h2 className="text-2xl">Log in</h2>
          <input
            type="password"
            id="password"
            name="password"
            className="bg-zinc-700 border-0 px-3 py-2 mt-3 rounded"
            onChange={(e) => {
              const value = e.target.value;
              setPwd(value);
            }}
          />
          <button
            className="cursor-pointer block px-5 py-2 mt-2 bg-red-700 rounded"
            type="submit"
          >
            Log in
          </button>
          {formError && <p className="text-red-300">Wrong password</p>}
        </form>
      </Layout>
    );
  }
};

export default WorkshopPage;
